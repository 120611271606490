// @ts-check

import './bootstrap';
import './global-functions';

if ($('.js-toast-flash-notification').length > 0) {
  window.Toast.fire({
    icon: $('.js-toast-flash-notification').data('type'),
    title: $('.js-toast-flash-notification').text(),
  });
}

/**
 * set auto focus
 */
$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});