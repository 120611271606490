$('.js-to-upper-case').on('keyup', (e) => {
  const $target = $(e.target);

  $target.val($target.val().toUpperCase());
});

$(() => {
  if (typeof $.fn.tooltip !== 'function') {
    return;
  }

  $('[data-toggle="tooltip"]').tooltip({
    html: true,
  });
});

$('.js-form-delete-confirmation').on('click', (e) => {
  Swal.fire({
    title: 'Peringatan!',
    icon: 'warning',
    text: 'Apakah Anda ingin menghapus data ini?',
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Tidak',
  }).then(({ isConfirmed }) => {
    if (isConfirmed === true) {
      $(e.currentTarget).closest('form').trigger('submit');
    }
  });
});

if ($.fn.dataTable !== undefined) {
  $('.js-data-table').dataTable();
}

if ($.fn.select2 !== undefined) {
  $('.js-select2').select2();
}

if ($.fn.datetimepicker !== undefined) {
  $('.js-datetimepicker-input').datetimepicker({
    format: 'DD/MM/YYYY HH:mm',
    icons: { time: 'far fa-clock' },
  });
}

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
  },
  xhrFields: {
    withCredentials: true,
  },
  crossDomain: true,
});

function convertToRupiah(value) {
  let rupiah = "";
  let angka = value.toString().split("").reverse().join("");
  for (var i = 0; i < angka.length; i++)
    if (i % 3 == 0) rupiah += angka.substr(i, 3) + ".";
  return rupiah
    .split("", rupiah.length - 1)
    .reverse()
    .join("");
}

function convertToAngka(value) {
  return parseInt(value.replace(/,.*|[^0-9]/g, ""), 10);
}

$('.card').on('input', '.js-input-price-converter', function(e) {
  let myval = convertToRupiah(convertToAngka($(this).val()));
  if (myval === "NaN") {
    $(this).val("");
  } else {
    $(this).val(myval);
  }
})

